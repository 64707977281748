import React, { useMemo } from 'react';
import styles from './index.module.less';
import { useTranslation } from 'next-i18next';
import { Button, FloatButton } from 'antd';
// import { useUserProvider } from '@/context/UserProvider';
import { useRouter } from 'next/router';
import { ga_logEvent } from '@/shared/ga';
import { EventName } from '@/shared/event-types';
import { imgUrl } from '@/shared/utils';
import { commonImgPath } from '@/shared/app-common';

const RegisterContent = ({ isIntersecting = false, onClick }: { isIntersecting?: boolean; onClick?: () => void }) => {
  const { t } = useTranslation();
  // const { onOpenLoginModal, onChangedLoginView, isLogin } = useUserProvider();
  const router = useRouter();

  const onDashboardClick = () => {
    router.push('/dashboard');
    // onClick && onClick();
    ga_logEvent(EventName.home_ADD_ACCOUNT);
  };

  const getContent = useMemo(() => {
    return (
      <div>
        {!isIntersecting && <FloatButton
          type="primary"
          className={styles.floatBtnContent}
          shape="square"
          style={{ right: 32 }}
          description={('Dashboard')}
          onClick={onDashboardClick}
        />}

        <div className={styles.content} style={{ backgroundImage: `url(${imgUrl(`${commonImgPath}/home-bottom-bg.webp`, 'mid')})` }}>
          <p className={styles.title}>{(`InstantKnow: Never Miss a Critical Website Change Again`)}</p>
          <p className={styles.desc}>{`Tired of missing important updates on a website? With InstantKnow, effortlessly track specific sections of any site, whether it's content updates, design changes, or pricing adjustments. Our AI-driven analysis highlights key insights, letting you focus on what matters. Get real-time alerts, visualize trends, and stay ahead—no update goes unnoticed.`}</p>
          <span className={styles.signUpBtn} onClick={onDashboardClick}>
            {('Dashboard')}
          </span>
        </div>
      </div>
    );
  }, [onDashboardClick]);

  return <>{getContent}</>;
};

export default RegisterContent;
